// extracted by mini-css-extract-plugin
export var container = "CTp4KfvL";
export var section = "aAHXw-fa";
export var frame = "RJ2ix-ij";
export var header = "IDRp8Jm3";
export var tab = "GCLyFhNA";
export var activeTab = "_2HzcJ4gM";
export var tabs = "_5zm3jrOb";
export var bar = "IYscIc3r";
export var dots = "+o5wMmtw";
export var dot = "prehIjZH";
export var redDot = "QTG7nrpi";
export var yellowDot = "hY0Rj2Db";
export var greenDot = "_4Q5F3jy9";